import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "../Styles/main.css";
import "../Styles/index.css";
import Nav from "../components/Nav";
import Logo from "../Images/bonsaiSocIcon.png";

const API_KEY = process.env.REACT_APP_API_KEY;
function About() {
  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const blurAmount = Math.min(scrollPosition / 1.4, 50);

    const blurElement = document.getElementById("blur-overlay");
    if (blurElement) {
      blurElement.style.backdropFilter = `blur(${blurAmount}px)`;
      blurElement.style.webkitBackdropFilter = `blur(${blurAmount}px)`; // For Safari
    }
  };

  useEffect(() => {
    // Dynamically import Tailwind CSS
    import("tailwindcss/tailwind.css");
  }, []);

  const SPREADSHEET_ID = "1SGuaS1L8-kwfoYi3bIjChlneccqHcSfI_EF8hYCQ1uY";

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Sheet1?key=${API_KEY}`
        );
        const rows = response.data.values;

        // Transform rows into the expected object format
        const formattedEvents = rows.slice(1).map((row, index) => ({
          heading: row[0], // Assuming the first column is the heading
          subHeading: row[1], // Assuming the second column is the subHeading
        }));

        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Timeline = ({ events }) => {
    return (
      <div className="flex flex-col gap-y-3 w-full my-4">
        <Circle />

        {events.map((event, key) => {
          // Dynamically determine direction based on index
          const direction = key % 2 === 0 ? "left" : "right";

          return (
            <Fragment key={key}>
              <div className="grid grid-cols-[1fr_auto_1fr] gap-x-2 items-center mx-auto">
                {direction === "left" ? (
                  <EventCard
                    heading={event.heading}
                    subHeading={event.subHeading}
                  />
                ) : (
                  <div></div>
                )}

                <Pillar />

                {direction === "right" ? (
                  <EventCard
                    heading={event.heading}
                    subHeading={event.subHeading}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              {key < events.length - 1 && <Circle />}
            </Fragment>
          );
        })}

        <Circle />
      </div>
    );
  };
  const Circle = () => {
    return <div className="rounded-full w-4 h-4 bg-green-500 mx-auto"></div>;
  };

  const Pillar = () => {
    return (
      <div className="rounded-t-full rounded-b-full w-2 h-full bg-green-500 mx-auto"></div>
    );
  };

  const EventCard = ({ heading, subHeading }) => {
    return (
      <div className="flex flex-col bg-white gap-y-2 border shadow-md rounded-xl p-4">
        <div className="font-bold text-lg border-b">{heading}</div>
        <div className="text-base text-gray-700">{subHeading}</div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Southend Bonsai Society | About Us</title>
        <meta
          name="description"
          content="Learn more about the Southend Bonsai Society, its history, and mission."
        />
        <meta
          name="keywords"
          content="Southend Bonsai Society, bonsai history, bonsai mission"
        />
        <meta
          property="og:title"
          content="Southend Bonsai Society | About Us"
        />
        <meta
          property="og:description"
          content="Learn more about the Southend Bonsai Society, its history, and mission."
        />
      </Helmet>
      <link rel="stylesheet" href="/public/Styles/main.css" />
      <link rel="stylesheet" href="/public/Styles/index.css" />
      <link rel="icon" href="/public/Images/bonsaiSocIcon.ico" />
      <Nav />
      <title>Southed Bonsai - Our History</title>
      <div
        id="content-wrapper"
        className="bg-custom-bg bg-no-repeat bg-center bg-fixed bg-cover"
      >
        <div id="blur-overlay">
          <div id="main">
            <div id="center">
              <div className="content-container">
                <h1 className="text-4em font-700">Our History</h1>
                <img className="icon" alt="Icon" src={Logo} />
                <Timeline events={events} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
